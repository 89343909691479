import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: "#584d20", // #E53935
                secondary: "#b9bf00", // #FFCDD2
                accent: "#584d20" // #3F51B5
            }
        }
    }
};

export default new Vuetify(opts);
